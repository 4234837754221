<template>
  <div>
    <div class="card">
      <h5>Mis Credenciales</h5>
      <div class="formgroup-inline" style="margin-top: 40px">
        <div class="field">
          <span class="p-float-label field">
            <InputText
              name="email"
              type="text"
              placeholder="Email"
              v-model="mail"
              disabled
              style="width: 200px"
            />
            <label for="email">Email</label>
          </span>
        </div>
        <div class="field">
          <span class="p-float-label field">
            <InputText type="password" v-model="password" />
            <label for="password">Contraseña Actual</label>
          </span>
        </div>
        <div class="field">
          <span class="p-float-label field">
            <InputText
              type="password"
              name="newPassword"
              v-model="newPassword"
            />
            <label for="newPassword">Nueva Contraseña</label>
          </span>
        </div>
        <div class="field">
          <span class="p-float-label field">
            <InputText
              type="password"
              name="newPasswordConfirm"
              v-model="newPasswordConfirm"
            />
            <label for="newPasswordConfirm">Confirmar Contraseña</label>
          </span>
        </div>
        <Button label="Guardar" @click="validations"></Button>
      </div>
    </div>
    <div class="card">
      <h5>Mi Ejecutivo</h5>
      <p>Nombre: Cristian Campos</p>
      <p>Email: cristian.campos@gmail.com</p>
      <p>Fono: +5698786867</p>
    </div>
  </div>
</template>
<script setup>
import { ref, defineProps } from "vue";
import { readUserData } from "@/services/user";
import { changePassword } from "@/api/auth";

const props = defineProps({
  showToast: Function,
});
const user = readUserData();
const mail = ref(user.email);
const password = ref("");
const newPassword = ref("");
const newPasswordConfirm = ref("");

const sendData = async () => {
  try {
    await changePassword({
      email: mail.value,
      password: password.value,
      newPassword: newPassword.value,
    });
    props.showToast(
      "success",
      "Listo!",
      "Tu contraseña se ha actualizado con éxito"
    );
  } catch (err) {
    if (err.response.status === 401)
      props.showToast("error", "Error", "Contraseña incorrecta");
    else
      props.showToast(
        "error",
        "Error",
        "No se ha podido actualizar la contraseña"
      );
  }
};
const validations = () => {
  if (newPassword.value !== newPasswordConfirm.value) {
    props.showToast("error", "Error", "Las contraseñas no coinciden");
    return;
  }
  if (newPassword.value.length < 6) {
    props.showToast(
      "error",
      "Error",
      "La contraseña debe tener al menos seis caracteres"
    );
    return;
  }
  sendData();
};
</script>
