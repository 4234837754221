<template>
  <div>
    <div class="card">
      <h5>Contacto Operativo</h5>
      <p>
        Estos son los contactos a los que informaremos cualquier tema operativo:
        Estado del servicio, información sobre envíos o retiros, ajustes en
        horarios de corte, movimientos de mercadería, entre otros.
      </p>
      <Button label="Agregar Contacto" @click="openModal" />

      <Dialog v-model:visible="visible" modal header="Crear Contacto">
        <div id="form">
          <div class="formgroup-inline">
            <span class="p-float-label field">
              <InputText
                id="contact-name"
                class="p-inputtext-lg"
                v-model="name"
              />
              <label for="name">Nombres</label>
            </span>

            <span class="p-float-label field">
              <InputText
                id="last-name"
                class="p-inputtext-lg"
                v-model="lastName"
              />
              <label for="last-name">Apellidos</label>
            </span>
          </div>
          <div class="formgroup-inline">
            <span class="p-float-label field">
              <InputText id="mail" class="p-inputtext-lg" v-model="email" />
              <label for="mail">Correo</label>
            </span>

            <span class="p-float-label field">
              <InputText id="phone" class="p-inputtext-lg" v-model="phone" />
              <label for="phone">Teléfono</label>
            </span>
          </div>
        </div>
        <template #footer>
          <Button
            label="Cancelar"
            icon="pi pi-times"
            @click="visible = false"
            text
          />
          <Button label="Listo" icon="pi pi-check" @click="save" autofocus />
        </template>
      </Dialog>
      <ConfirmDialog />
      <div v-if="loading">
        <ProgressSpinner
          style="width: 50px; height: 50px; margin-top: 20px"
          class="spinner"
          strokeWidth="8"
          fill="var(--surface-ground)"
          animationDuration=".5s"
        />
      </div>
      <DataTable
        :value="props.customer.contacts"
        showGridlines
        tableStyle="min-width: 50rem; margin-top:20px"
        v-else-if="props.customer.contacts.length > 0"
      >
        <Column field="email" header="Correo"></Column>
        <Column field="name" header="Nombre"></Column>
        <Column field="last_name" header="Apellido"></Column>
        <Column field="phone" header="Teléfono"></Column>
        <Column header="Acción">
          <template #body="{ data }">
            <div class="p-d-flex p-ai-center">
              <i @click="openModal(data)" class="pi pi-pencil edit"></i>
              <i @click="remove(data.id)" class="pi pi-times delete"></i>
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>
<script setup>
import { defineProps, ref } from "vue";
import { saveContact, deleteContact } from "@/api/contact";
import ProgressSpinner from "primevue/progressspinner";
import Dialog from "primevue/dialog";
import ConfirmDialog from "primevue/confirmdialog";
import { useConfirm } from "primevue/useconfirm";
import { isValidEmail } from "@/services/utils";

const confirm = useConfirm();

const name = ref("");
const lastName = ref("");
const email = ref("");
const phone = ref("");
const contactId = ref(null);

const loading = ref(false);
const visible = ref(false);
const props = defineProps({
  showToast: Function,
  customer: Object,
  getCustomerData: Function,
});

const clearFields = () => {
  name.value = "";
  lastName.value = "";
  email.value = "";
  phone.value = "";
  contactId.value = null;
};

const openModal = (contact) => {
  if (!contact) {
    clearFields();
    visible.value = true;
    return;
  }
  visible.value = true;
  name.value = contact.name;
  lastName.value = contact.last_name;
  email.value = contact.email;
  phone.value = contact.phone;
  contactId.value = contact.id;
};

const remove = async (id) => {
  confirm.require({
    message: "Seguro que quieres eliminar este contacto?",
    header: "Confirmación",
    icon: "pi pi-info-circle",
    acceptClass: "p-button-danger",
    acceptLabel: "Si",
    rejectLabel: "No",
    accept: async () => {
      try {
        loading.value = true;
        await deleteContact(id);
        loading.value = false;
        props.getCustomerData();
      } catch (err) {
        props.showToast("error", "Error", err);
      }
    },
  });
};

const validateFields = () => {
  if (!name.value) {
    props.showToast("error", "Error", "El nombre es requerido.");
    return false;
  }
  if (!lastName.value) {
    props.showToast("error", "Error", "El apellido es requerido.");
    return false;
  }
  if (!isValidEmail(email.value)) {
    props.showToast("error", "Error", "El email no tiene el formato correcto.");
    return false;
  }
  if (!phone.value) {
    props.showToast("error", "Error", "El teléfono es requerido.");
    return false;
  }
  return true;
};

const save = async () => {
  if (!validateFields()) {
    return;
  }
  try {
    visible.value = false;
    loading.value = true;
    const payload = {
      name: name.value,
      lastName: lastName.value,
      email: email.value,
      phone: phone.value,
      customerId: props.customer.id,
    };
    if (contactId.value) {
      payload.id = contactId.value;
    }
    await saveContact(payload);
    props.showToast(
      "success",
      "Listo!",
      "Información de contacto guardada con éxito."
    );
    loading.value = false;
    props.getCustomerData();
  } catch (err) {
    console.log(err);
    props.showToast("error", "Error", err);
    loading.value = false;
  }
};
</script>

<style scoped>
.delete {
  margin-left: 1rem;
  color: #a02626;
  cursor: pointer;
}
.edit {
  color: #4b5c81;
  cursor: pointer;
}
.field {
  margin-top: 20px;
}
</style>
