<template>
  <div class="layout-dashboard">
    <div class="grid">
      <div class="col-12 lg:col-12">
        <div class="card statistics">
          <Accordion :multiple="true">
            <AccordionTab header="Mi cuenta">
              <Acount :showToast="showToast" />
            </AccordionTab>
            <AccordionTab header="Contacto Administrativo">
              <AdminContact
                :showToast="showToast"
                :customer="customer"
                :getCustomerData="getCustomerData"
              />
            </AccordionTab>
            <AccordionTab
              header="Contacto Operativo"
              v-if="!isMobile() && customer.name"
            >
              <OperativeContact
                :customer="customer"
                :showToast="showToast"
                :getCustomerData="getCustomerData"
              />
            </AccordionTab>
            <AccordionTab
              header="Canales de Venta"
              v-if="!isMobile() && customer.name"
            >
              <SaleChannels :customer="customer" :showToast="showToast" />
            </AccordionTab>
            <AccordionTab header="Couriers" v-if="!isMobile() && customer.name">
              <Couriers :customer="customer" :showToast="showToast" />
            </AccordionTab>
            <AccordionTab
              header="Direcciones"
              v-if="!isMobile() && customer.name"
            >
              <Addresses :customer="customer" :showToast="showToast" />
            </AccordionTab>
            <AccordionTab header="Empaques" v-if="!isMobile() && customer.name">
              <Packaging :customer="customer" :showToast="showToast" />
            </AccordionTab>
          </Accordion>
        </div>
      </div>
      <Toast />
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import Acount from "./Acount.vue";
import AdminContact from "./AdminContact.vue";
import OperativeContact from "./OperativeContact.vue";
import SaleChannels from "./SaleChannels.vue";
import Couriers from "./Couriers.vue";
import Addresses from "./Addresses.vue";
import Packaging from "./Packaging.vue";
import { isMobile } from "@/services/utils";
import { useToast } from "primevue/usetoast";
import { findByUser } from "@/api/customer";
import { readUserData } from "@/services/user";

const user = readUserData();
const toast = useToast();
let customer = ref({});

const getCustomerData = async () => {
  try {
    const response = await findByUser(user.id);
    customer.value = response.data;
  } catch (err) {
    showToast("error", "Error", "Ha ocurrido un error al cargar los datos");
  }
};

getCustomerData();

const showToast = (severity, summary, detail) => {
  toast.add({
    severity: severity,
    summary: summary,
    detail: detail,
    life: 7000,
  });
};
</script>
