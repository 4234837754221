<template>
  <div>
    <div class="card">
      <h5>Contacto Administrativo</h5>
      <p>
        Ingrese la información necesaria para servicio logístico. A esta persona
        se le manda la información de facturación.
      </p>
      <div class="formgroup-inline">
        <span class="p-float-label field">
          <InputText
            id="company-name"
            class="p-inputtext-lg large"
            v-model="name"
          />
          <label for="company-name">Nombre de la empresa</label>
        </span>

        <span class="p-float-label field">
          <InputMask id="basic" mask="99999999-*" v-model="rut" />

          <label for="company-rut">Rut de la empresa</label>
        </span>

        <span class="p-float-label field">
          <InputText id="company-web" class="p-inputtext-lg" v-model="web" />
          <label for="company-web">Sietio web</label>
        </span>
      </div>
      <div class="formgroup-inline">
        <span class="p-float-label field">
          <InputText
            id="company-activity"
            class="p-inputtext-lg large"
            v-model="activity"
          />
          <label for="company-activity">Giro o Actividad</label>
        </span>

        <span class="p-float-label field">
          <InputText
            id="company-realname"
            class="p-inputtext-lg large"
            v-model="realName"
          />
          <label for="company-realname">Razón social</label>
        </span>
      </div>
      <div class="formgroup-inline">
        <span class="p-float-label field">
          <InputText
            id="company-mail"
            class="p-inputtext-lg large"
            v-model="email"
          />
          <label for="company-mail">Correo de facturación</label>
        </span>
        <span class="p-float-label field">
          <InputText
            id="company-phone"
            class="p-inputtext-lg"
            v-model="phone"
          />
          <label for="company-phone">Teléfono de facturación</label>
        </span>
      </div>
      <div class="formgroup-inline">
        <span class="p-float-label field">
          <InputText
            id="company-adress"
            class="p-inputtext-lg large"
            v-model="address"
          />
          <label for="company-adress">Dirección de facturación</label>
        </span>
      </div>
      <Button label="Guardar" @click="save"></Button>
    </div>
  </div>
</template>
<script setup>
import { ref, defineProps, watch } from "vue";
import { saveCustomer } from "@/api/customer";
import { readUserData } from "@/services/user";
import { rutValidator } from "@/services/utils";
import InputMask from "primevue/inputmask";
import { isValidEmail } from "@/services/utils";

const user = readUserData();
const props = defineProps({
  showToast: Function,
  customer: Object,
  getCustomerData: Function,
});
const name = ref("");
const rut = ref("");
const web = ref("");
const activity = ref("");
const realName = ref("");
const email = ref("");
const phone = ref("");
const address = ref("");

watch(
  () => props.customer,
  (newValue) => {
    name.value = newValue.name;
    rut.value = newValue.rut;
    web.value = newValue.web;
    activity.value = newValue.activity;
    realName.value = newValue.real_name;
    email.value = newValue.email;
    phone.value = newValue.phone;
    address.value = newValue.address;
  }
);

const validateFields = () => {
  if (!name.value) {
    props.showToast("error", "Error", "Debe ingresar el nombre de la empresa");
    return false;
  }
  if (!rut.value) {
    props.showToast("error", "Error", "Debe ingresar el rut de la empresa");
    return false;
  }
  if (!rutValidator.isValid(rut.value)) {
    props.showToast("error", "Error", "El rut no tiene el formato correcto");
    return false;
  }
  if (!web.value) {
    props.showToast(
      "error",
      "Error",
      "Debe ingresar el sitio web de la empresa"
    );
    return false;
  }
  if (!activity.value) {
    props.showToast(
      "error",
      "Error",
      "Debe ingresar el giro o actividad de la empresa"
    );
    return false;
  }
  if (!realName.value) {
    props.showToast(
      "error",
      "Error",
      "Debe ingresar la razón social de la empresa"
    );
    return false;
  }
  if (!isValidEmail(email.value)) {
    props.showToast("error", "Error", "El email no tiene el formato correcto");
    return false;
  }
  if (!phone.value) {
    props.showToast(
      "error",
      "Error",
      "Debe ingresar el teléfono de facturación de la empresa"
    );
    return false;
  }
  if (!address.value) {
    props.showToast(
      "error",
      "Error",
      "Debe ingresar la dirección de facturación de la empresa"
    );
    return false;
  }
  return true;
};

const save = async () => {
  if (!validateFields()) return;
  const payload = {
    name: name.value,
    rut: rut.value,
    web: web.value,
    activity: activity.value,
    realName: realName.value,
    email: email.value,
    phone: phone.value,
    address: address.value,
    userId: user.id,
  };
  try {
    await saveCustomer(payload);
    props.showToast(
      "success",
      "Listo!",
      "Información de contacto guardada con éxito."
    );
    props.getCustomerData();
  } catch (err) {
    props.showToast("error", "Error", err);
  }
};
</script>
<style scoped>
.formgroup-inline {
  margin-top: 30px;
}
.large {
  width: 300px;
}
.p-inputtext-lg {
  font-size: 14px;
}
</style>
