<template>
  <div>
    <div class="card">
      <h5>Direcciones</h5>
      <div>
        <div class="left">
          <Message severity="info" icon="pi-send" :closable="false">
            Administra tus direcciones almacenadas para facilitar la creación de
            envíos. El contacto permanente es la información que se muestra en
            la etiqueta</Message
          >
        </div>
        <div class="right">
          Origen predeterminado: <b>{{ findDefaultOriginAddress() }}</b> <br />
          Develución predeterminado: <b>{{ findDefaulReturnAddress() }}</b>
        </div>
      </div>
      <button
        class="p-button p-button-rounded p-button-primary"
        @click="openModal"
      >
        <i class="pi pi-plus" /> &nbsp; Agregar Dirección
      </button>
      <ProgressSpinner
        v-if="loading"
        style="width: 50px; height: 50px; margin-top: 20px; margin-left: 40%"
        class="spinner"
        strokeWidth="8"
        fill="var(--surface-ground)"
        animationDuration=".5s"
      />
      <DataTable
        :value="addresses"
        showGridlines
        tableStyle="min-width: 50rem; margin-top:20px"
        v-else
      >
        <Column header="Alias" field="alias"> </Column>
        <Column header="Direccion">
          <template #body="{ data }">
            {{ data.address }} {{ data.address_number }}
          </template>
        </Column>
        <Column header="Contacto" field="contact_name"></Column>
        <Column header="Origen" field="origin"> </Column>
        <Column header="Destino" field="destination"> </Column>
        <Column header="Devolución" field="return"> </Column>
        <Column header="Acciones">
          <template #body="{ data }">
            <i class="pi pi-search clickeable" @click="openModal(data)"></i>
            &nbsp;
            <i class="pi pi-trash clickeable" @click="deleteDialog(data)"></i>
          </template>
        </Column>
      </DataTable>
    </div>
    <Dialog v-model:visible="showModal" modal header="Datos de la dirección">
      <div id="form">
        <div class="formgroup-inline form-line">
          <span class="p-float-label field">
            <InputText class="p-inputtext-lg input" v-model="address.alias" />
            <label for="name">Alias</label>
          </span>
          <span class="p-float-label">
            <InputText
              class="p-inputtext-lg input"
              v-model="address.contact_name"
            />
            <label for="name">Nombre de contacto</label>
          </span>
        </div>
        <div class="formgroup-inline form-line">
          <span class="p-float-label field">
            <InputText
              class="p-inputtext-lg input"
              v-model="address.contact_phone"
            />
            <label for="name">Teléfono de contacto</label>
          </span>
          <span class="p-float-label field">
            <InputText
              class="p-inputtext-lg input"
              v-model="address.contact_email"
            />
            <label for="name">Email de contacto</label>
          </span>
        </div>
        <div class="formgroup-inline form-line">
          <span class="p-float-label field">
            <Dropdown
              v-model="selectedRegion"
              placeholder="Seleccione una región"
              :options="regions"
              optionLabel="name"
              style="width: 200px"
            />
            <label for="name">Region</label>
          </span>
          <span class="p-float-label field">
            <Dropdown
              v-model="selectedCity"
              placeholder="Seleccione una comuna"
              :options="cities"
              optionLabel="countyName"
              style="width: 200px"
            />
            <label for="name">Comuna</label>
          </span>
        </div>
        <div class="formgroup-inline form-line">
          <span class="p-float-label field">
            <InputText class="p-inputtext-lg input" v-model="address.address" />
            <label for="name">Dirección</label>
          </span>
          <span class="p-float-label field">
            <InputText
              class="p-inputtext-lg input"
              v-model="address.address_number"
            />
            <label for="name">Numeración</label>
          </span>
        </div>
        <div class="formgroup-inline form-line">
          <span class="p-float-label field">
            <Dropdown
              v-model="address.origin"
              placeholder="Origen"
              :options="typeValues"
              style="width: 200px"
              class="p-inputtext-lg input"
            />
            <label for="name">Origen</label>
          </span>
          <span class="p-float-label field">
            <Dropdown
              v-model="address.destination"
              placeholder="Destino"
              :options="typeValues"
              style="width: 200px"
              class="p-inputtext-lg input"
            />
            <label for="name">Destino</label>
          </span>
        </div>
        <div class="formgroup-inline form-line">
          <span class="p-float-label field">
            <Dropdown
              v-model="address.return"
              placeholder="Devolución"
              :options="typeValues"
              style="width: 200px"
              class="p-inputtext-lg input"
            />
            <label for="name">Devolución</label>
          </span>
          <span class="p-float-label field">
            <InputText
              class="p-inputtext-lg input"
              v-model="address.complement"
            />
            <label for="name">Complemento</label>
          </span>
        </div>
        <Button label="Guardar" icon="pi pi-check" @click="save" />
      </div>
    </Dialog>
  </div>
</template>
<script setup>
import { defineProps, ref, watch } from "vue";
import Message from "primevue/message";
import { getAddresses, saveAddress, deleteAddress } from "@/api/address";
import { getRegions } from "@/api/region";
import Dropdown from "primevue/dropdown";
import { getAreas } from "@/api/courier";
import ProgressSpinner from "primevue/progressspinner";
import { useConfirm } from "primevue/useconfirm";
import { isValidEmail } from "@/services/utils";
const props = defineProps({
  showToast: Function,
  customer: Object,
});

const confirm = useConfirm();
const typeValues = ["DESHABILITADO", "HABILITADO", "PREDETERMINADO"];
const addresses = ref([]);
const showModal = ref(false);
const address = ref({
  destination: typeValues[0],
  origin: typeValues[0],
  return: typeValues[0],
});
const regions = ref([]);
const selectedRegion = ref({});
const selectedCity = ref({});
const cities = ref([]);
const loading = ref(false);

watch(
  () => selectedRegion.value,
  () => {
    loadCities();
  }
);

const deleteDialog = (removingAddress) => {
  confirm.require({
    message: "¿Está seguro de eliminar esta dirección?",
    header: "Confirmación",
    icon: "pi pi-info-alert",
    acceptLabel: "Si",
    rejectLabel: "No",
    accept: () => {
      remove(removingAddress);
    },
  });
};

const remove = async (removingAddress) => {
  try {
    await deleteAddress(removingAddress.id);
    props.showToast("success", "Éxito", "Dirección eliminada correctamente.");
    loadAddresses();
  } catch (error) {
    props.showToast("error", "Error", "Error al eliminar la dirección.");
  }
};

const loadCities = async () => {
  const { data } = await getAreas(1, selectedRegion.value.code);
  cities.value = data.coverageAreas;
  if (selectedCity.value.countyName) {
    selectedCity.value = cities.value.find(
      (city) => city.countyName === selectedCity.value.countyName
    );
  }
};

const validateFields = () => {
  if (!address.value.alias) {
    props.showToast("error", "Error", "El alias es requerido.");
    return false;
  }
  if (!address.value.contact_name) {
    props.showToast("error", "Error", "El nombre de contacto es requerido.");
    return false;
  }
  if (!address.value.contact_phone) {
    props.showToast("error", "Error", "El teléfono de contacto es requerido.");
    return false;
  }
  if (!isValidEmail(address.value.contact_email)) {
    props.showToast("error", "Error", "El email no tiene el formato correcto.");
    return false;
  }
  if (!selectedRegion.value.iso_code) {
    props.showToast("error", "Error", "Debe seleccionar una región.");
    return false;
  }
  if (!selectedCity.value.countyName) {
    props.showToast("error", "Error", "Debe seleccionar una comuna.");
    return false;
  }
  if (!address.value.address) {
    props.showToast("error", "Error", "La dirección es requerida.");
    return false;
  }
  if (!address.value.contact_email) {
    props.showToast("error", "Error", "La numeración es requerida.");
    return false;
  }

  return true;
};

const save = async () => {
  if (!validateFields()) return;
  try {
    address.value.customer_id = props.customer.id;
    address.value.region_iso_code = selectedRegion.value.iso_code;
    address.value.city = selectedCity.value.countyName;
    await saveAddress(address.value);
    props.showToast("success", "Listo!", "Dirección guardada con éxito.");
    loadAddresses();
    showModal.value = false;
  } catch {
    props.showToast(
      "error",
      "Error",
      "Ocurrió un error al guardar la dirección"
    );
  }
};

const openModal = (newAddress = {}) => {
  if (newAddress.id) {
    address.value = newAddress;
    if (newAddress.region_code) {
      selectedRegion.value = regions.value.find(
        (region) => region.code === newAddress.region_code
      );
    }
    if (newAddress.city) {
      selectedCity.value.countyName = newAddress.city;
    }
  } else {
    address.value = {
      destination: typeValues[0],
      origin: typeValues[0],
      return: typeValues[0],
    };
  }
  showModal.value = true;
};

const loadRegions = async () => {
  try {
    const { data } = await getRegions();
    regions.value = data;
  } catch {
    props.showToast(
      "error",
      "Error",
      "Ocurrió un error al obtener las regiones"
    );
  }
};

const loadAddresses = async () => {
  loading.value = true;
  try {
    const { data } = await getAddresses(props.customer.id);
    addresses.value = data;
    loading.value = false;
  } catch {
    props.showToast(
      "error",
      "Error",
      "Ocurrió un error al obtener las direcciones"
    );
    loading.value = false;
  }
};

const findDefaultOriginAddress = () => {
  const address = addresses.value.find(
    (address) => address.origin === "PREDETERMINADO"
  );
  return address ? address.alias : "No Asignada";
};

const findDefaulReturnAddress = () => {
  const address = addresses.value.find(
    (address) => address.return === "PREDETERMINADO"
  );
  return address ? address.alias : "No Asignada";
};

loadAddresses();
loadRegions();
</script>
<style scoped>
.left {
  float: left;
  width: 70%;
}
.right {
  padding: 20px;
  float: right;
  width: 30%;
  height: 100px;
}
.form-line {
  margin-top: 15px;
}
.clickeable {
  cursor: pointer;
}
</style>
