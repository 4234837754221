<template>
  <div>
    <div class="card">
      <h5>Empaques</h5>
      <button
        class="p-button p-button-rounded p-button-primary"
        @click="openModal"
      >
        <i class="pi pi-plus" /> &nbsp; Nuevo Empaque
      </button>
      <DataTable
        :value="sizes"
        showGridlines
        tableStyle="min-width: 50rem; margin-top:20px"
      >
        <Column header="Alias" field="alias"> </Column>
        <Column header="Dimensiones">
          <template #body="{ data }">
            {{ data.width }} x {{ data.height }} x {{ data.length }}
          </template>
        </Column>
        <Column header="Peso" field="physical_weight"></Column>
        <Column header="Descripción" field="description"> </Column>
        <Column header="Predeterminado">
          <template #body="{ data }">
            {{ data.default ? "Si" : "No" }}
          </template>
        </Column>
        <Column header="Acciones">
          <template #body="{ data }">
            <i class="pi pi-search clickeable" @click="openModal(data)"></i>
            &nbsp;
            <i class="pi pi-trash clickeable" @click="deleteDialog(data)"></i>
          </template>
        </Column>
      </DataTable>
    </div>
    <Dialog v-model:visible="showModal" modal header="Datos del empaque">
      <div id="form">
        <div class="formgroup-inline form-line">
          <span class="p-float-label field">
            <InputText class="p-inputtext-lg input" v-model="size.alias" />
            <label for="name">Alias</label>
          </span>
          <span class="p-float-label">
            <InputText
              class="p-inputtext-lg input"
              v-model="size.description"
            />
            <label for="name">Descripción</label>
          </span>
        </div>
        <div class="formgroup-inline form-line">
          <span class="p-float-label field">
            <InputText class="p-inputtext-lg input" v-model="size.height" />
            <label for="name">Alto</label>
          </span>
          <span class="p-float-label">
            <InputText class="p-inputtext-lg input" v-model="size.width" />
            <label for="name">Ancho</label>
          </span>
        </div>
        <div class="formgroup-inline form-line">
          <span class="p-float-label field">
            <InputText class="p-inputtext-lg input" v-model="size.length" />
            <label for="name">Largo</label>
          </span>
          <span class="p-float-label">
            <InputText
              class="p-inputtext-lg input"
              v-model="size.volumetric_weight"
            />
            <label for="name">Peso Volumétrico</label>
          </span>
        </div>
        <div class="formgroup-inline form-line">
          <span class="p-float-label field">
            <InputText
              class="p-inputtext-lg input"
              v-model="size.physical_weight"
            />
            <label for="name">Peso Físico</label>
          </span>
          <span class="p-float-label">
            <div style="margin-bottom: 5px">Empaque predeterminado</div>
            <InputSwitch v-model="size.default" />
          </span>
        </div>
        <Button label="Guardar" icon="pi pi-check" @click="save" />
      </div>
    </Dialog>
  </div>
</template>
<script setup>
import InputSwitch from "primevue/inputswitch";
import { getSizes, saveSize, deleteSize } from "@/api/size";
import { ref, defineProps } from "vue";
import { useConfirm } from "primevue/useconfirm";

const sizes = ref([]);
const size = ref({});
const showModal = ref(false);
const confirm = useConfirm();

const props = defineProps({
  showToast: Function,
  customer: Object,
});

const save = async () => {
  try {
    if (!validateFields()) return;
    if (!size.value.id) size.value.customer_id = props.customer.id;
    if (!size.value.default) size.value.default = false;
    await saveSize(size.value);
    loadSizes();
    showModal.value = false;
    props.showToast("success", "Empaque guardado correctamente");
  } catch (error) {
    props.showToast("error", "Error al guardar el empaque");
  }
};

const loadSizes = async () => {
  const { data } = await getSizes(props.customer.id);
  sizes.value = data;
};

const openModal = (selectedSize = {}) => {
  if (selectedSize.id) size.value = selectedSize;
  else size.value = {};
  showModal.value = true;
};

const deleteDialog = (selectedSize) => {
  confirm.require({
    message: "¿Está seguro de eliminar este empaque?",
    header: "Confirmación",
    icon: "pi pi-info-alert",
    acceptLabel: "Si",
    rejectLabel: "No",
    accept: () => {
      remove(selectedSize);
    },
  });
};

const remove = async (selectedSize) => {
  try {
    await deleteSize(selectedSize.id);
    loadSizes();
    props.showToast("success", "Empaque eliminado correctamente");
  } catch (error) {
    props.showToast("error", "Error al eliminar el empaque");
  }
};

const validateFields = () => {
  if (!size.value.alias) {
    props.showToast("error", "El alias es requerido");
    return false;
  }
  if (!size.value.description) {
    props.showToast("error", "La descripción es requerida");
    return false;
  }
  if (!size.value.height) {
    props.showToast("error", "La altura es requerida");
    return false;
  }
  if (!size.value.width) {
    props.showToast("error", "El ancho es requerido");
    return false;
  }
  if (!size.value.length) {
    props.showToast("error", "El largo es requerido");
    return false;
  }
  if (!size.value.volumetric_weight) {
    props.showToast("error", "El peso volumétrico es requerido");
    return false;
  }
  if (!size.value.physical_weight) {
    props.showToast("error", "El peso físico es requerido");
    return false;
  }
  return true;
};

loadSizes();
</script>

<style scoped>
.form-line {
  margin-top: 20px;
}
.clickeable {
  cursor: pointer;
}
</style>
