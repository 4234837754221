<template>
  <div>
    <div class="card">
      <h5>Canales de Venta</h5>
      <p>
        Conecta tus tiendas y marketplaces para rescatar tus ventas y
        automatizar tu operación.
      </p>
      <DataTable
        :value="channels"
        showGridlines
        tableStyle="min-width: 50rem; margin-top:20px"
      >
        <Column header="Plataforma Ecommerce">
          <template #body="{ data }">
            <div class="p-d-flex p-ai-center">
              <img
                :src="`/layout/images/channels/${data.icon}`"
                :alt="data.name"
                class="p-mr-2"
              />
            </div>
          </template>
        </Column>
        <Column header="Conexión">
          <template #body="{ data }">
            <div class="p-d-flex p-ai-center">
              <span v-if="data.enabled === null">Sin Conectar</span>
              <InputSwitch
                v-else
                v-model="data.enabled"
                @change="toogleEnable(data)"
              />
            </div>
          </template>
        </Column>
        <Column header="Configuración">
          <template #body="{ data }">
            <div class="p-d-flex p-ai-center">
              <a v-if="data.enabled === null" @click="connectSaleChannel(data)">
                Conectar
              </a>
              <a v-else @click="openModal(data)"> Editar </a>
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
  <Dialog v-model:visible="showModal" modal :header="currentSaleChannel.name">
    <div id="form">
      <div
        class="formgroup-inline"
        v-for="attribute in attributes"
        v-bind:key="attribute.id"
      >
        <span class="p-float-label field">
          <InputText class="p-inputtext-lg input" v-model="attribute.value" />
          <label for="name">{{ attribute.description }}</label>
        </span>
        <i
          class="pi pi-question-circle"
          v-if="attribute.help"
          @click="openHelpLink(attribute.help)"
        ></i>
      </div>
      <Button label="Guardar" icon="pi pi-check" @click="save" autofocus />
    </div>
  </Dialog>
</template>
<script setup>
import { defineProps, ref } from "vue";
import { toogleSaleChannel, enableSaleChannel } from "@/api/customer";
import { getSaleChannels } from "@/api/sale-channel";
import InputSwitch from "primevue/inputswitch";
import { setConfig, getAttributes } from "@/api/sale-channel";

const showModal = ref(false);
const channels = ref([]);
const currentSaleChannel = ref({});
const attributes = ref([]);

const props = defineProps({
  showToast: Function,
  customer: Object,
});

const loadData = async () => {
  try {
    const response = await getSaleChannels(props.customer.id);
    channels.value = response.data;
  } catch (err) {
    props.showToast("error", "Error", err);
  }
};

const toogleEnable = async (saleChannel) => {
  try {
    const payload = {
      customerId: props.customer.id,
      saleChannelId: saleChannel.id,
      enabled: saleChannel.enabled,
    };
    await toogleSaleChannel(payload);
    props.showToast("success", "Listo!", "Información guardada.");
  } catch (err) {
    props.showToast("error", "Error", err);
  }
};

const connectSaleChannel = async (saleChannel) => {
  // TODO: Implementar la conexión de canales de venta
  try {
    const payload = {
      customerId: props.customer.id,
      saleChannelId: saleChannel.id,
    };
    await enableSaleChannel(payload);
    props.showToast("success", "Listo!", "Conexión exitosa.");
    loadData();
  } catch (err) {
    props.showToast("error", "Error", err);
  }
};

const openModal = async (saleChannel) => {
  currentSaleChannel.value = saleChannel;
  await loadAttributes();
  showModal.value = true;
};

const loadAttributes = async () => {
  try {
    const resp = await getAttributes(currentSaleChannel.value.id);
    attributes.value = resp.data;
    attributes.value.forEach((attribute) => {
      attribute.value = "";
    });
  } catch (err) {
    props.showToast("error", "Error", err);
  }
};

const save = () => {
  const config = {};
  attributes.value.forEach((attribute) => {
    config[attribute.name] = attribute.value;
  });
  const payload = {
    customerId: props.customer.id,
    saleChannelId: currentSaleChannel.value.id,
    config: config,
  };
  try {
    setConfig(payload);
    props.showToast("success", "Listo!", "Configuración exitosa.");
    showModal.value = false;
  } catch (err) {
    props.showToast("error", "Error", err);
  }
};

const openHelpLink = (link) => {
  window.open(link, "_blank");
};

loadData();
</script>
<style scoped>
a {
  color: #007bff;
  cursor: pointer;
}
.input {
  width: 250px;
  margin-bottom: 10px;
}
#form {
  padding-top: 20px;
}
.pi {
  cursor: pointer;
}
</style>
