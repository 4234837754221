<template>
  <div>
    <div class="card">
      <h5>Activar couriers</h5>
      <DataTable
        :value="couriers"
        showGridlines
        tableStyle="min-width: 50rem; margin-top:20px"
      >
        <Column header="Courier">
          <template #body="{ data }">
            <div class="p-d-flex p-ai-center">
              <img
                :src="`/layout/images/couriers/${data.icon}`"
                :alt="data.name"
                class="p-mr-2"
                width="70"
              />
            </div>
          </template>
        </Column>
        <Column field="service" header="Servicio"></Column>
        <Column header="Conexión">
          <template #body="{ data }">
            <div class="p-d-flex p-ai-center">
              <InputSwitch
                v-model="data.enabled"
                @change="toogleEnable(data)"
              />
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>
<script setup>
import InputSwitch from "primevue/inputswitch";
import { defineProps, ref } from "vue";
import { getCouriers, toogleCourier } from "@/api/courier";

const couriers = ref([]);

const props = defineProps({
  showToast: Function,
  customer: Object,
});

const loadData = async () => {
  try {
    const response = await getCouriers(props.customer.id);
    couriers.value = response.data;
  } catch (err) {
    props.showToast("error", "Error", err);
  }
};

const toogleEnable = async (courier) => {
  try {
    const payload = {
      customerId: props.customer.id,
      courierId: courier.id,
      enabled: courier.enabled,
    };
    await toogleCourier(payload);
    props.showToast("success", "Listo!", "Información guardada.");
    loadData();
  } catch (err) {
    props.showToast("error", "Error", err);
  }
};

loadData();
</script>
